<script>
import VActivityForm from "@/components/Forms/VActivityForm";

import ActivityCategoryService from "@/services/ActivityCategoryService";
import ActivityService from "@/services/ActivityService";
import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VIndex",
    components: { VActivityForm },
    mixins: [AppCfg],
    data() {
        return {
            editContext: { empty: true },
            slugContext: "",
            editModal: false,
            loading: false,
            groups: []
        };
    },
    computed: {
        langs: function() {
            return this.appCfgRead("Activities.langs");
        },
        invoiceSetups() {
            return this.appCfgRead("Projects.invoice_setup");
        }
    },
    async created() {
        if (!this.$aclGuard(this.$ACL.TIMEREG_MGMT)) {
            return;
        }

        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);
        await this.fetchData();
        let matchIDX = 16;
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const r = await ActivityCategoryService.GET();
                this.groups = r.data.data;
            } catch (e) {
                console.log("fetchData exception", e);
            }

            this.loading = false;
        },
        setContext(act, groupSlug) {
            this.slugContext = groupSlug;
            this.editContext = act;
            this.editModal = true;
        },
        async handleDeleteActivity(act) {
            this.$swal({
                icon: "warning",
                text: this.$t("activities.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    if ((await this.deleteActivity(act)) == true) {
                        this.$notify({
                            message: this.$t(
                                "activities.notify_activity_deleted"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success"
                        });
                        // Just remove - no need to reload
                        this.groups.forEach((val, idx) => {
                            let delIdx = val.activities.findIndex(el => {
                                return el.id == act.id;
                            });

                            if (delIdx != -1) {
                                val.activities.splice(delIdx, 1);
                            }
                        });
                    }
                }
            });
        },
        async deleteActivity(act) {
            try {
                const r = await ActivityService.DELETE(act.id);
                return true;
            } catch (e) {
                console.log(e);
                if ("errors" in e.response.data) {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: e.response.data.errors.join(".\n")
                    });
                } else {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_delete"),
                        text: this.$t("unknown_error")
                    });
                }
            }

            return false;
        },
        async handleChange() {
            this.editModal = false;
            await this.fetchData();
        }
    }
};
</script>

<template>
    <div class="v-activities">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12"></div>
                <v-loader :loading="loading" v-if="loading" />
                <template v-else-if="groups.length">
                    <div class="col-12" v-for="group in groups" :key="group.id">
                        <card body-classes="px-0">
                            <div
                                class="d-flex justify-content-between align-items-center"
                            >
                                <h3 class="px-4 mb-4">{{ group.name }}</h3>
                                <base-button
                                    v-if="ACL_RW && group.editable == 1"
                                    @click="
                                        setContext(
                                            {
                                                empty: true,
                                                activity_category_id: group.id
                                            },
                                            group.algo_slug
                                        )
                                    "
                                    size="sm"
                                    type="primary"
                                    class="mx-4 mb-4"
                                    ><i class="far fa-plus"></i>
                                    {{
                                        $t("activities.create_new")
                                    }}</base-button
                                >
                            </div>
                            <div class="px-4 mb-4">
                                <p>
                                    <i class="far fa-info mr-2"></i>
                                    {{
                                        $t(
                                            "activities.help_text_preference_fields"
                                        )
                                    }}
                                </p>
                            </div>
                            <div class="table-responsive">
                                <v-table class="table table-sm table-mg">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>{{
                                                $t("activities.name")
                                            }}</v-th>
                                            <v-th class="text-center">{{
                                                $t("activities.status")
                                            }}</v-th>
                                            <v-th class="text-center">{{
                                                $t("activities.inv_setup_MEG")
                                            }}</v-th>
                                            <v-th class="text-center">{{
                                                $t(
                                                    "activities.inv_setup_PROJECTS"
                                                )
                                            }}</v-th>
                                            <v-th class="text-center">{{
                                                $t(
                                                    "activities.inv_setup_MES"
                                                )
                                            }}</v-th>
                                            <v-th class="text-center">{{
                                                $t("activities.number_of_tasks")
                                            }}</v-th>
                                            <v-th
                                                v-for="(lang, idx) in langs"
                                                :key="idx"
                                                class="text-left"
                                            >
                                                {{ lang }}
                                            </v-th>

                                            <v-th v-if="ACL_RW"></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="act in group.activities"
                                            :key="act.id"
                                        >
                                            <v-td
                                                :class="
                                                    act.active == 0
                                                        ? 'text-danger'
                                                        : ''
                                                "
                                            >
                                                {{ act.name }}</v-td
                                            >
                                            <v-td
                                                class="text-center"
                                                :class="
                                                    act.active == 0
                                                        ? 'text-danger'
                                                        : ''
                                                "
                                                >{{ act.active_name }}</v-td
                                            >
                                            <v-td class="text-center">
                                                <i
                                                    class="far"
                                                    :class="{
                                                        'fa-check text-success':
                                                            act.pref_meg === 1,
                                                        'fa-times text-warning':
                                                            act.pref_meg === 0
                                                    }"
                                                ></i>
                                            </v-td>
                                            <v-td class="text-center"
                                                ><i
                                                    class="far"
                                                    :class="{
                                                        'fa-check text-success':
                                                            act.pref_projects ===
                                                            1,
                                                        'fa-times text-warning':
                                                            act.pref_projects ===
                                                            0
                                                    }"
                                                ></i
                                            ></v-td>
                                            <v-td class="text-center"
                                                ><i
                                                    class="far"
                                                    :class="{
                                                        'fa-check text-success':
                                                            act.pref_mes ===
                                                            1,
                                                        'fa-times text-warning':
                                                            act.pref_mes ===
                                                            0
                                                    }"
                                                ></i
                                            ></v-td>

                                            <v-td class="text-center">
                                                {{ $n(act.task_count) }}
                                            </v-td>

                                            <v-td
                                                v-for="(lang, idx) in langs"
                                                :key="idx"
                                                class=""
                                            >
                                                <template
                                                    v-if="
                                                        'available_translations' in
                                                            act &&
                                                            act.available_translations.includes(
                                                                idx
                                                            )
                                                    "
                                                >
                                                    {{
                                                        act
                                                            .invoice_translations[
                                                            idx
                                                        ]
                                                    }}
                                                </template>

                                                <i
                                                    v-else
                                                    class="far fa-times text-muted"
                                                >
                                                </i>
                                            </v-td>

                                            <v-td
                                                class="text-right"
                                                v-if="ACL_RW"
                                            >
                                                <base-dropdown
                                                    v-if="group.editable"
                                                    :menu-on-right="true"
                                                    :has-toggle="false"
                                                >
                                                    <template
                                                        slot="title-container"
                                                        ><i
                                                            class="far fa-ellipsis-h fa-lg"
                                                        ></i
                                                    ></template>
                                                    <div
                                                        class="dropdown-item"
                                                        @click="
                                                            setContext(
                                                                act,
                                                                group.algo_slug
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="far fa-fw fa-pencil"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "activities.action_edit"
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        v-if="
                                                            act.task_count == 0
                                                        "
                                                        class="dropdown-item text-danger"
                                                        @click="
                                                            handleDeleteActivity(
                                                                act
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="far fa-fw fa-trash"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "activities.action_delete"
                                                            )
                                                        }}
                                                    </div>
                                                </base-dropdown>

                                                <i
                                                    v-else
                                                    class="far fa-fw fa-ban"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </card>
                    </div>
                </template>
            </div>
        </div>
        <portal to="modals">
            <v-activity-form
                :context="editContext"
                :slug-context="slugContext"
                v-if="editModal"
                @close="editModal = false"
                @close-refresh="handleChange"
            />
        </portal>
    </div>
</template>

<style></style>
